import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import ButtonBase from "@material-ui/core/ButtonBase"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    borderRadius: "00px",
  },
  image: {
    width: 128,
    height: 128,
    "&:hover": {
      cursor: "default",
      pointerEvents: "none",
    },
    // backgroundColor: `${primary}`,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "visible",
  },
}))

export default function ServiceItem({ icon, title, body, alt }) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <Paper
        className={classes.paper}
        style={{ borderRadius: "6px" }}
        elevation={2}
      >
        <Grid container spacing={matchesXS ? 0 : 2}>
          <Grid item>
            <ButtonBase className={classes.image} disableRipple>
              <img className={classes.img} alt={alt} src={icon} />
            </ButtonBase>
          </Grid>

          <Grid item xs={7} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant="h6"
                  style={{ marginTop: "1em", color: `${primary}` }}
                  gutterBottom
                >
                  {title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {body}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
