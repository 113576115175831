import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { NormalField } from "reactstrap-form-fields"
import Button from "../components/btn"
import blob from "../assets/images/blob.svg"
import waves from "../assets/images/waves.svg"
import mobileblob from "../assets/images/mobileblob.svg"
import customemail from "../assets/images/customemail.svg"
import Hr from "../components/hr"
import scatterblob from "../assets/images/scatterblob.svg"
import triangleblob from "../assets/images/triangleblob.svg"
import cornerblob from "../assets/images/cornerblob.svg"
import responsivelayers from "../assets/images/responsivelayers.svg"
import greenblob from "../assets/images/greenblob.svg"
import ServiceItem from "../components/serviceItem"
import secure from "../assets/images/secure.svg"
import servers from "../assets/images/servers.svg"
import domains from "../assets/images/domains.svg"
import Fab from "@material-ui/core/Fab"
// import Link from "../components/link"
import { Link } from "gatsby"
import Hidden from "@material-ui/core/Hidden"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import {
  FaHandHoldingUsd,
  FaHandPointUp,
  FaBloggerB,
  FaCameraRetro,
  FaGlobe,
  FaCommentDots,
  FaStore,
  FaHome,
  FaBlog,
  FaArchway,
  FaPhotoVideo,
  FaTicketAlt,
  FaChartArea,
  FaTools,
  FaArrowRight,
} from "react-icons/fa"

import { Col, Row } from "reactstrap"
import Grid from "@material-ui/core/Grid"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    paddingLeft: "25em",
    paddingRight: "25em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "5em",
      paddingRight: "5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  rowContainerlg: {
    paddingLeft: "15em",
    paddingRight: "15em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "5em",
      paddingRight: "5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  usecases: {
    backgroundImage: `url(${greenblob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    borderTop: "3px solid #4eb9b0",
    [theme.breakpoints.down("xs")]: {
      backgroundAttachment: "scroll",
    },
  },
  circlesbgimage: {
    backgroundImage: `url(${scatterblob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("xs")]: {
      backgroundAttachment: "scroll",
    },
  },
  fastbgimage: {
    backgroundImage: `url(${triangleblob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("xs")]: {
      backgroundAttachment: "scroll",
    },
  },
  analyticbgimage: {
    backgroundImage: `url(${cornerblob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("xs")]: {
      backgroundAttachment: "scroll",
    },
  },
  responsiveimage: {
    paddingTop: "3em",
    backgroundImage: `url(${responsivelayers})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.down("xs")]: {
      backgroundAttachment: "scroll",
    },
  },
  useItem: {
    fontSize: "1.25rem",
    color: "white",
    fontWeight: 500,
  },
  useIcon: {
    color: "#26a27b",
    verticalAlign: "center",
    alignItems: "center",
    marginRight: 10,
  },
  margin: {
    margin: theme.spacing(1),
  },
  // extendedIcon: {
  //   marginRight: theme.spacing(1),
  // },
}))

const Background = styled.div`
  background-image: url(${blob});
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  color: white;
  padding: 10rem 0;
  text-align: center;
  @media only screen and (max-width: 800px) {
    background-image: url(${mobileblob});
  }
  /* clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%); */
  /* background: linear-gradient(60deg, #147452 25%, #26a27b 92.13%, #3bb18c 100%); */
  /* background-color: ${primary}; */
`
const BoxStyling = styled.div`
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  border-color: #f4f5f7;
  transition-duration: 0.3s;
  text-align: start;
  border-top: 2px solid ${(props) => props.theme.primary};
  p {
    color: black;
  }
`

const Directory = styled.section`
  background-color: #f8f9fa;
  height: 60vh;
  width: 100%;
  padding-top: 2.5em;
  @media only screen and (max-width: 1290px) {
    height: 75vh;
  }
  @media only screen and (max-width: 960px) {
    height: 100vh;
  }
  @media only screen and (max-width: 600px) {
    height: 115vh;
  }
`

const BenefitSection = styled.section`
  background-color: #f8f9fa;
  height: 115vh;
  padding-top: 2.5em;
`
const CallToAction = styled.section`
  background-image: url(${waves});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 120vh;
  padding-top: 2.5em;
  @media only screen and (max-width: 600px) {
    background-attachment: scroll;
  }
  @media only screen and (max-width: 1290px) {
    height: 150vh;
  }
`

const Benefits = () => {
  const [hover, sethover] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Layout>
      <Seo
        title="Benefits"
        description="Snappy Websites are incredibly fast and efficient. Explore all of the benefits that Snappy Web Design has to offer."
      />
      <Background>
        <h1
          style={{
            color: `${primary}`,
          }}
        >
          Explore the Benefits
        </h1>
        <h6 style={{ color: "black" }}>
          of choosing{" "}
          <span style={{ fontWeight: 700, color: `${primary}` }}>Snappy</span>{" "}
        </h6>
      </Background>
      <Grid container direction="column">
        <Directory>
          <Grid container direction="row" className={classes.rowContainerlg}>
            <Grid container direction="column" spacing={matchesXS ? 10 : 5}>
              <Grid
                container
                direction="row"
                spacing={matchesXS ? 2 : 5}
                className="mt-5"
                style={{ marginLeft: matchesXS ? 0 : matchesSM ? "1.5em" : 0 }}
              >
                <ServiceItem
                  icon={servers}
                  alt="cartoon of servers"
                  title="Snappy Web Hosting"
                  body="Optionally, achieve the most optimal user experience by using Snappy's cloud hosting."
                />
                <ServiceItem
                  icon={domains}
                  title="Web Domains"
                  alt="cartoon of web browser"
                  body="Just getting started? Let Snappy get you everything you need to get going."
                />
                <ServiceItem
                  icon={customemail}
                  title="Custom Email"
                  alt="cartoon of email envelope"
                  body="Host with Snappy and get 5 aliases, free forever. Canyou@believeit.com?"
                />
                <ServiceItem
                  icon={secure}
                  title="SSL Encryption"
                  alt="cartoon of shield with checkmark"
                  body="Host via Snappy for a free certificate to secure your customers' traffic."
                />
              </Grid>
            </Grid>
          </Grid>
        </Directory>
        <BenefitSection
          style={{ height: matchesXS ? "140vh" : "120vh" }}
          className={classes.usecases}
        >
          <Grid
            container
            direction="row"
            className={classes.rowContainerlg}
            alignItems="center"
            style={{ marginTop: matchesXS ? undefined : "10%" }}
          >
            <Grid container direction="row">
              <Grid item container md={6}>
                <Grid item>
                  <h2
                    style={{
                      fontWeight: 600,
                      color: "white",
                      fontSize: "2.25rem",
                      letterSpacing: 1.5,
                      paddingBottom: ".5em",
                    }}
                  >
                    Use Cases
                  </h2>
                </Grid>
                <Grid item>
                  <h5 style={{ color: "white", fontWeight: 300 }}>
                    Whether you need a blog, an e-commerce store, or complex API
                    integrations, Snappy can do it.{" "}
                  </h5>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mt-5">
                <Grid item container direction="column" md={6}>
                  <Grid item style={{ marginBottom: "1em" }}>
                    <span
                      style={{
                        fontWeight: "500",
                        color: "#b6e3d5",
                        textTransform: "uppercase",
                      }}
                    >
                      By category
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaHandPointUp className={classes.useIcon} size={20} />{" "}
                      E-Commerce
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaBloggerB className={classes.useIcon} size={20} /> Blogs
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaCameraRetro className={classes.useIcon} size={20} />{" "}
                      Content & Media
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaGlobe className={classes.useIcon} size={20} />{" "}
                      Nonprofit Organizations
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaCommentDots className={classes.useIcon} size={20} />{" "}
                      Discussion Boards
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaHandHoldingUsd className={classes.useIcon} size={20} />{" "}
                      Services
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaStore className={classes.useIcon} size={20} />{" "}
                      Restaraunts
                    </span>
                  </Grid>
                </Grid>
                <Grid item container direction="column" md={6}>
                  <Grid
                    item
                    style={{
                      marginBottom: "1em",
                      marginTop: matchesXS ? "2em" : undefined,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        color: "#b6e3d5",
                        textTransform: "uppercase",
                        paddingTop: matchesXS ? "5em" : undefined,
                      }}
                    >
                      By type of site
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaHome className={classes.useIcon} size={20} /> Company
                      Homepage
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaBlog className={classes.useIcon} size={20} /> Company
                      Blog
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaArchway className={classes.useIcon} size={20} /> Custom
                      Landing Pages
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaPhotoVideo className={classes.useIcon} size={20} />{" "}
                      Professional Image Gallery
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaTicketAlt className={classes.useIcon} size={20} />{" "}
                      Event Site
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaTools className={classes.useIcon} size={20} /> Client
                      Management Site
                    </span>
                  </Grid>
                  <Grid item className="py-1 my-1">
                    <span className={classes.useItem}>
                      <FaChartArea className={classes.useIcon} size={20} /> Data
                      Visualization
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BenefitSection>
        <BenefitSection
          className={classes.circlesbgimage}
          style={{ height: matchesXS ? "100vh" : undefined }}
        >
          <Grid
            container
            direction="row"
            className={classes.rowContainerlg}
            alignItems="center"
            style={{ marginTop: matchesXS ? "20%" : "15%" }}
          >
            <Grid container direction="row">
              <Grid item container lg={6}>
                <Grid item>
                  <h2 style={{ color: `${primary}` }}>SEO</h2>
                </Grid>
                <p>
                  A websites position or 'ranking' on search engines is the
                  result of a mathematical formula measuring how well it stands
                  up to specific performance metrics and best practices. Some of
                  the most heavily weighted metrics are: page speed, mobile
                  usability/optimization, accessibility features, and a backend
                  that communicates relevant parts of webpages to "robo web
                  crawlers." Our websites perform exceptionally well on Google,
                  because they're designed for Google. Read more about our{" "}
                  <Link to="/seo-services/" style={{ fontWeight: 600 }}>
                    SEO Services
                  </Link>
                  .
                </p>
              </Grid>
              <Grid
                item
                container
                lg={6}
                justify={matchesMD ? "center" : "flex-end"}
                style={{ marginTop: matchesMD ? "3em" : undefined }}
              >
                <StaticImage
                  src="../assets/images/searchengine.svg"
                  alt="Popular Search Engines"
                  placeholder="blurred"
                  layout="constrained"
                  height={175}
                />
              </Grid>
            </Grid>
          </Grid>
        </BenefitSection>
        <BenefitSection className={classes.responsiveimage}>
          <Grid
            container
            direction="row"
            className={classes.rowContainerlg}
            alignItems="center"
            style={{ marginTop: matchesMD ? "7%" : "18%" }}
          >
            <Grid container direction="row">
              <Grid
                item
                container
                lg={6}
                justify="center"
                style={{
                  marginTop: matchesMD ? "3em" : undefined,
                  marginBottom: matchesMD ? "3em" : undefined,
                }}
              >
                <StaticImage
                  src="../assets/images/mobile.svg"
                  alt="Drawing of iPhone"
                  placeholder="blurred"
                  layout="constrained"
                  height={250}
                />
              </Grid>

              <Grid item container lg={6}>
                <Grid item>
                  <h2 style={{ color: `${primary}` }}>Responsive Design</h2>
                </Grid>
                <p>
                  In 2020, 68% of all global web traffic originated from mobile
                  devices. With this number steadily increasing year over year,
                  mobile friendly sites are now a necessity. The top search
                  enginges agree, giving precedence to those websites which
                  provide seamless mobile experiences. Snappy excels in this
                  category - our websites intelligently and automatically load
                  smaller images and resources when viewed on smaller devices -
                  saving bandwidth, and increasing performance. Read more about
                  our{" "}
                  <Link to="/websitedesign/" style={{ fontWeight: 600 }}>
                    Web Design Services
                  </Link>
                  .
                </p>
              </Grid>
            </Grid>
          </Grid>
        </BenefitSection>
        <BenefitSection className={classes.fastbgimage}>
          <Grid
            container
            direction="row"
            className={classes.rowContainerlg}
            alignItems="center"
            style={{ marginTop: "15%" }}
          >
            <Grid
              container
              direction="row"
              style={{ marginTop: matchesMD ? "3em" : undefined }}
            >
              <Grid item container lg={6}>
                <Grid item>
                  <h2 style={{ color: `${primary}` }}>
                    Near-instant page loads
                  </h2>
                </Grid>
                <p>
                  Our websites leverage the latest and greatest technology,
                  which loads critical parts of the page, and not a second
                  sooner than the user needs it. We further optimize our
                  websites by using intelligent tools that compress resources
                  without risk of diminished quality or functionality. All of
                  this results in a lightweight package with incredibly fast
                  load times. When navigating from page to page, everything just
                  feels...there. Avoid frustration from slow websites, get
                  Snappy. Read more about our{" "}
                  <Link to="/websitedevelopment/" style={{ fontWeight: 600 }}>
                    Web Development Services
                  </Link>
                  .
                </p>
              </Grid>
              <Grid
                item
                container
                lg={6}
                justify={matchesMD ? "center" : "flex-end"}
                style={{ marginTop: matchesMD ? "3em" : undefined }}
              >
                <StaticImage
                  src="../assets/images/drawnrocket.svg"
                  alt="cartoon rocket launching"
                  placeholder="blurred"
                  layout="constrained"
                  height={300}
                />
              </Grid>
            </Grid>
          </Grid>
        </BenefitSection>
        <BenefitSection
          className={classes.analyticbgimage}
          style={{ height: matchesMD ? "130vh" : undefined }}
        >
          <Grid
            container
            direction="row"
            className={classes.rowContainerlg}
            alignItems="center"
            style={{ marginTop: "18%" }}
          >
            <Grid container direction="row">
              <Grid
                item
                container
                lg={6}
                justify={matchesMD ? "center" : "flex-start"}
              >
                <StaticImage
                  src="../assets/images/analytics.svg"
                  alt="cartoon of computer"
                  placeholder="blurred"
                  layout="constrained"
                  height={250}
                />
              </Grid>

              <Grid
                item
                container
                lg={6}
                style={{ marginTop: matchesMD ? "3em" : undefined }}
              >
                <Grid item>
                  <h2 style={{ color: `${primary}` }}>Analytics</h2>
                </Grid>
                <p>
                  Gain better insights into who your prospective users/customers
                  are, and how they engage with your website. View and analyze
                  behavioral trends and traffic funnels. With Snappy, you have
                  greater access to relevant data that can provide you with
                  fruitful insights.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </BenefitSection>
        <CallToAction>
          <Grid
            container
            direction="row"
            className={classes.rowContainer}
            justify="center"
          >
            <Grid
              item
              style={{
                width: matchesXS
                  ? undefined
                  : matchesSM
                  ? undefined
                  : matchesMD
                  ? "40rem"
                  : "40rem",
              }}
            >
              <BoxStyling
                style={{
                  marginTop: matchesXS ? undefined : matchesMD ? "20%" : "30%",
                }}
              >
                <Row className="py-5">
                  <Col md={12} className="m-auto">
                    <h4 className="text-center text-secondary">
                      Get your project started:
                    </h4>
                    <Hr />
                    <form
                      name="Benefits"
                      method="POST"
                      netlify
                      data-netlify="true"
                    >
                      <input type="hidden" name="form-name" value="Benefits" />
                      <NormalField name="Name" />
                      <NormalField name="Email" />
                      <NormalField name="Phone Number" />
                      <NormalField name="Message" type="textarea" />
                      <Button type="submit">Submit</Button>
                    </form>
                  </Col>
                </Row>
              </BoxStyling>
            </Grid>
          </Grid>
        </CallToAction>
      </Grid>
      <Hidden xsDown>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          className={classes.margin}
          style={{
            position: "fixed",
            right: 20,
            bottom: 10,
            backgroundColor: "#e1f4ef",
            color: "#5bbfa0",
          }}
          onMouseOver={() => sethover(true)}
          onMouseOut={() => sethover(false)}
        >
          <Link to="/pricing/" style={{ textDecoration: "none" }}>
            {hover && " Pricing "}
            <FaArrowRight
              // className={classes.extendedIcon}
              style={{
                marginLeft: hover ? 2 : 2.5,
                marginRight: hover ? 2 : 2.5,
              }}
              size={15}
            />
          </Link>
        </Fab>
      </Hidden>
    </Layout>
  )
}

export default Benefits
